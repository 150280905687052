var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-component',{staticClass:"base-component",attrs:{"title":"Board Timeline (all times are UTC)"}},[_c('div',{staticClass:"component-content"},[_c('div',{staticClass:"p-2 ml-8"},[_vm._v(" Charting "+_vm._s(_vm.activeOptions.term === "day" ? "posts/day" : _vm.activeOptions.property === "postsPerMinute" ? "posts/minute" : "relative activity")+" ")]),_c('div',{staticClass:"flex flex-wrap px-12"},[_c('div',{staticClass:"option-group"},[_c('button',{staticClass:"option-button",class:{ 'option-button--selected': _vm.activeOptions.term == 'day' },on:{"click":function($event){return _vm.setActiveTerm('day')}}},[_vm._v(" Daily ")]),_c('button',{staticClass:"option-button",class:{ 'option-button--selected': _vm.activeOptions.term == 'hour' },on:{"click":function($event){return _vm.setActiveTerm('hour')}}},[_vm._v(" Hourly ")]),_c('button',{staticClass:"option-button",class:{
            'option-button--selected': _vm.activeOptions.term == 'cycle'
          },on:{"click":function($event){return _vm.setActiveTerm('cycle')}}},[_vm._v(" Cycle ")])]),_c('div',{staticClass:"option-group"},_vm._l(({
            day: [
              [365 * 20, 'All'],
              [365 * 5, '5y'],
              [365 * 3, '3y'],
              [365 * 1, '1y'],
              [365 * 0.5, '6m']
            ],
            hour: [
              [28, '4w'],
              [7, '1w'],
              [3, '3d']
            ],
            cycle: [
              [3, '3d'],
              [1, '1d']
            ]
          }[_vm.activeOptions.term]),function(option){return _c('button',{key:option[1],staticClass:"option-button option-button--narrow",class:{
            'option-button--selected': _vm.activeOptions.days == option[0]
          },on:{"click":function($event){return _vm.setChartOption('days', option[0])}}},[_vm._v(" "+_vm._s(option[1])+" ")])}),0),(_vm.activeOptions.term == 'day')?_c('div',{staticClass:"option-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeOptions.dateStartString),expression:"activeOptions.dateStartString"}],attrs:{"type":"date"},domProps:{"value":(_vm.activeOptions.dateStartString)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.activeOptions, "dateStartString", $event.target.value)},function($event){return _vm.setTimelineRange()}]}})]):_vm._e(),(_vm.activeOptions.term == 'day')?_c('div',{staticClass:"option-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeOptions.dateEndString),expression:"activeOptions.dateEndString"}],attrs:{"type":"date"},domProps:{"value":(_vm.activeOptions.dateEndString)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.activeOptions, "dateEndString", $event.target.value)},function($event){return _vm.setTimelineRange()}]}})]):_vm._e(),(['hour', 'cycle'].includes(_vm.activeOptions.term))?_c('div',{staticClass:"option-group"},[_c('button',{staticClass:"option-button",class:{
            'option-button--selected': _vm.activeOptions.property == 'postsPerMinute'
          },on:{"click":function($event){return _vm.setChartOption('property', 'postsPerMinute')}}},[_vm._v(" Posts/Minute ")]),_c('button',{staticClass:"option-button",class:{
            'option-button--selected': _vm.activeOptions.property == 'activity'
          },on:{"click":function($event){return _vm.setChartOption('property', 'activity')}}},[_vm._v(" Activity ")])]):_vm._e(),(['hour', 'cycle'].includes(_vm.activeOptions.term))?_c('div',{staticClass:"option-group"},[_c('button',{staticClass:"option-button",class:{
            'option-button--selected': _vm.activeOptions.smoothingLevel == 0
          },on:{"click":function($event){return _vm.setChartOption('smoothingLevel', 0)}}},[_vm._v(" Accurate ")]),_c('button',{staticClass:"option-button",class:{
            'option-button--selected': _vm.activeOptions.smoothingLevel == 3
          },on:{"click":function($event){return _vm.setChartOption('smoothingLevel', 3)}}},[_vm._v(" Smooth ")]),_c('button',{staticClass:"option-button",class:{
            'option-button--selected': _vm.activeOptions.smoothingLevel == 6
          },on:{"click":function($event){return _vm.setChartOption('smoothingLevel', 6)}}},[_vm._v(" Silky ")])]):_vm._e(),_c('div',{staticClass:"option-group"},[_c('button',{staticClass:"option-button",class:{ 'option-button--selected': _vm.activeOptions.yIsLimited },on:{"click":_vm.toggleYLimit}},[_vm._v(" Limit y-axis ")])])]),_c('div',{staticClass:"chart-wrapper"},[_c('canvas',{attrs:{"id":"myChart"}})]),_c('div',{staticClass:"flex flex-wrap justify-center text-xs p-4"},[_vm._l((_vm.allBoards),function(board){return _c('div',{key:board,staticClass:"board-button m-1 w-16 text-center border border-gray-600",on:{"click":function($event){return _vm.toggleBoard(board)}}},[_c('div',{staticClass:"cursor-pointer px-0 py-2",class:{ 'board-button--selected': _vm.graphedBoards.includes(board) }},[_vm._v(" "+_vm._s(board == "s4s" ? "[s4s]" : "/" + board + "/")+" ")])])}),_c('div',{staticClass:"board-button m-1 w-16 text-center border border-gray-600",on:{"click":function($event){return _vm.toggleBoard('all')}}},[_c('div',{staticClass:"cursor-pointer px-0 py-2",class:{ 'board-button--selected': _vm.graphedBoards.includes('all') }},[_vm._v(" /all/ ")])])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }