const VERSION = 14
if (localStorage.getItem("VERSION") != VERSION) {
  console.log("Site version changed! Clearing localstorage!")
  localStorage.clear()
  localStorage.setItem("VERSION", VERSION)
}

let config = {
  VERSION,
  debugLevelProduction: "info",
  debugLevelDevelopment: "trace",
  //url: "http://localhost:4001",
  url: "https://api.4stats.io",
  safeInitialBoards: ["an", "ck", "diy", "gd", "n", "o", "p", "po", "sci", "tg", "trv", "vr"],
  availableBoards: {
    main: [
      "3",
      "a",
      "adv",
      "an",
      "asp",
      "bant",
      "biz",
      "cgl",
      "ck",
      "co",
      "diy",
      "fa",
      "fit",
      "g",
      "gd",
      "his",
      "ic",
      "int",
      "jp",
      "k",
      "lgbt",
      "lit",
      "m",
      "mlp",
      "mu",
      "n",
      "news",
      "o",
      "out",
      "p",
      "po",
      "pol",
      "pw",
      "qst",
      "r9k",
      "sci",
      "sp",
      "tg",
      "toy",
      "trv",
      "tv",
      "v",
      "vg",
      "vm",
      "vmg",
      "vp",
      "vr",
      "vrpg",
      "vst",
      "vt",
      "wsr",
      "x",
      "xs",
      // image generals
      "c",
      "cm",
      "i",
      "w",
      "wg",
      "wsg",
      //misc
      "f",
      "qa",
      "vip"
    ],
    nsfw: ["aco", "b", "d", "e", "gif", "h", "hc", "hm", "hr", "r", "s", "s4s", "soc", "t", "trash", "u", "y"]
  },
  boardNames: {
    3: "3DCG",
    a: "Anime & Manga",
    aco: "Adult Cartoons",
    adv: "Advice",
    an: "Animals & Nature",
    asp: "Alternative Sports & Wrestling",
    b: "Random",
    bant: "International/Random",
    biz: "Business & Finance",
    c: "Anime/Cute",
    cgl: "Cosplay & EGL",
    ck: "Food & Cooking",
    cm: "Cute/Male",
    co: "Comics & Cartoons",
    d: "Hentai/Alternative",
    diy: "Do It Yourself",
    e: "Ecchi",
    f: "Flash",
    fa: "Fashion",
    fit: "Fitness",
    g: "Technology",
    gd: "Graphic Design",
    gif: "Adult GIF",
    h: "Hentai",
    hc: "Hardcore",
    his: "History & Humanities",
    hm: "Handsome Men",
    hr: "High Resolution",
    i: "Oekaki",
    ic: "Artwork/Critique",
    int: "International",
    jp: "Otaku Culture",
    k: "Weapons",
    lgbt: "LGBT",
    lit: "Literature",
    m: "Mecha",
    mlp: "Pony",
    mu: "Music",
    n: "Transportation",
    news: "Current News",
    o: "Auto",
    out: "Outdoors",
    p: "Photography",
    po: "Papercraft & Origami",
    pol: "Politically Incorrect",
    pw: "Professional Wrestling",
    qa: "Question & Answer",
    qst: "Quests",
    r: "Adult Requests",
    r9k: "ROBOT9001",
    s: "Sexy Beautiful Women",
    s4s: "Shit 4chan Says",
    sci: "Science & Math",
    soc: "Cams & Meetups",
    sp: "Sports",
    t: "Torrents",
    tg: "Traditional Games",
    toy: "Toys",
    trash: "Off-Topic",
    trv: "Travel",
    tv: "Television & Film",
    u: "Yuri",
    v: "Video Games",
    vg: "Video Game Generals",
    vip: "Very Important Posts",
    vm: "Video Games/Multiplayer",
    vmg: "Video Games/Mobile",
    vp: "Pokémon",
    vr: "Retro Games",
    vrpg: "Video Games/RPG",
    vst: "Video Games/Strategy",
    vt: "Virtual YouTubers",
    w: "Anime/Wallpapers",
    wg: "Wallpapers/General",
    wsg: "Worksafe GIF",
    wsr: "Worksafe Requests",
    x: "Paranormal",
    xs: "Extreme Sports",
    y: "Yaoi"
  }
}

config.allBoards = [...config.availableBoards.main, ...config.availableBoards.nsfw, "qb"].sort()

export default config
