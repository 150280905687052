import Vue from "vue"
import App from "./views/app.vue"
import router from "./router"
import store from "./store/index.js"

Vue.config.productionTip = false

//import "material-colors/dist/colors.css"
import "@/css/main.scss"

import pino from "./js/pino"
pino.info("NODE_ENV is:", process.env.NODE_ENV)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
