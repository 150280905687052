var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"boardlist-component relative cursor-pointer"},[_c('div',{staticClass:"row h-10 text-xs border-b-2 border-gray-300 categories"},_vm._l(([
        { category: 'name', text: 'Board', tooltip: '' },
        {
          category: 'postsPerMinute',
          text: 'Posts/min',
          tooltip: 'Over the last 30 minutes'
        },
        {
          category: 'threadsPerHour',
          text: 'Threads/hour',
          tooltip: 'Over the last hour',
          classes: ['is-hidden-below-desktop']
        },
        {
          category: 'avgPostsPerDay',
          text: 'Avg.Posts/day',
          tooltip: 'Over the last 4 weeks. Weighted towards more recent weeks.'
        },
        {
          category: 'relativeActivity',
          text: 'Activity Now',
          tooltip: 'Current activity relative to the boards usual daily posts/minute peak',
          classes: ['']
        },
        {
          category: 'activityThisToD',
          text: 'rel. to ToD',
          tooltip: 'Current activity relative to the boards average posts/minute this time of day over the last 8 weeks',
          classes: ['is-hidden-below-fullhd']
        }
      ]),function(item){return _c('div',{key:item.name,class:[
        {
          'font-bold': _vm.sortBoardListBy == item.category,
          'tooltip--bottom': item.tooltip
        } ].concat( item.classes
      ),attrs:{"data-hover-text":item.tooltip},on:{"click":function($event){$event.stopPropagation();return _vm.categoryClicked(item.category)}}},[_vm._v(" "+_vm._s(item.text)+" "),(_vm.sortBoardListBy == item.category)?_c('div',{staticClass:"sortArrow",class:{
          'sortArrow--reversed': _vm.sortBoardListBy == 'name' ? !_vm.reverseOrder : _vm.reverseOrder
        }}):_vm._e()])}),0),(_vm.combinedBoardStats.avgPostsPerDay)?_c('transition-group',{staticClass:"board-rows text-sm",attrs:{"tag":"div"}},_vm._l((_vm.sortedBoardlist),function(boardName){return _c('div',{key:boardName,staticClass:"row border-b border-gray-400",class:{ 'row--selected': _vm.selectedBoard == boardName },attrs:{"id":'board-' + boardName},on:{"click":function($event){$event.stopPropagation();return _vm.boardClicked(boardName)}}},[_vm._o(_c('div',{staticClass:"tooltip--right",attrs:{"data-hover-text":_vm.longBoardNames[boardName]}},[_vm._v(" "+_vm._s(boardName == "s4s" ? "[s4s]" : "/" + boardName + "/")+" ")]),0,boardName),_c('div',{},[_vm._v(" "+_vm._s((_vm.boardData[boardName].postsPerMinute || 0).toFixed(2))+" ")]),_c('div',{staticClass:"is-hidden-below-desktop"},[_vm._v(" "+_vm._s(Math.round(_vm.boardData[boardName].threadsPerHour))+" ")]),_c('div',{},[_vm._v(" "+_vm._s(_vm.boardData[boardName].postCountDevelopment && false ? _vm.boardData[boardName].postCountDevelopment.toFixed(2) : "")+" "+_vm._s(boardName == "qb" ? "?" : Math.round(_vm.boardData[boardName].avgPostsPerDay))+" ")]),_c('div',{},[_vm._v(" "+_vm._s(boardName == "qb" ? "?" : _vm.boardData[boardName].relativeActivity >= 0 ? Math.round(_vm.boardData[boardName].relativeActivity * 100) + "%" : "-")+" ")]),_c('div',{staticClass:"is-hidden-below-fullhd"},[_vm._v(" "+_vm._s(boardName == "qb" ? "?" : _vm.boardData[boardName].activityThisToD >= 0 ? Math.round(_vm.boardData[boardName].activityThisToD * 100) + "%" : "-")+" ")]),(_vm.boardData[boardName].hasSticky)?_c('img',{staticClass:"row--has-sticky",attrs:{"src":require("@/assets/sticky.gif")}}):_vm._e()])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }